import React from 'react'
import { Link, graphql } from 'gatsby'
import classnames from 'classnames'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import AriaModal from 'react-aria-modal'
import { Date, RichText } from 'prismic-reactjs'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { createRichTextSerializer } from '../utils/prismic'

import Layout from '../components/layout'
import SEO from '../components/seo'

import ArticlesColumn from '../components/ArticlesColumn'

import quoteIcon from '../images/quote.svg'
import latestArticleImage from '../images/related-3.jpg'
import arrow from '../images/cta-arrow.svg'

export const query = graphql`
  query($uid: String!, $focusAreaId: String!) {
    prismic {
      article(uid: $uid, lang: "en-us") {
        _meta {
          uid
        }
        title
        image
        focusarea {
          ... on PRISMIC_Focus_area {
            _meta {
              uid
            }
            name
          }
        }
        author {
          ... on PRISMIC_Article_author {
            name
            title
            photo
            bio
          }
        }
        author_section_title
        body {
          ... on PRISMIC_ArticleBodyText {
            type
            primary {
              text
            }
          }
          ... on PRISMIC_ArticleBodyQuote {
            type
            primary {
              quote
            }
          }
          ... on PRISMIC_ArticleBodyImage {
            type
            primary {
              image
              caption
            }
          }
          ... on PRISMIC_ArticleBodyPodcast {
            type
            primary {
              soundcloud_embed
            }
          }
        }
        expert {
          ... on PRISMIC_Expert {
            name
            title
            short_bio
            full_bio
            photo
          }
        }
        expert2 {
          ... on PRISMIC_Expert {
            name
            title
            short_bio
            full_bio
            photo
          }
        }
        highlights {
          highlight_text
        }
        _meta {
          firstPublicationDate
        }
      }

      latestArticles: allArticles(
        sortBy: meta_firstPublicationDate_DESC
        first: 20
        where: { focusarea: $focusAreaId }
      ) {
        totalCount
        edges {
          cursor
          node {
            _meta {
              uid
              firstPublicationDate
            }
            title
            image
            is_hidden
            focusarea {
              ... on PRISMIC_Focus_area {
                _meta {
                  uid
                }
                name
              }
            }
            body {
              ... on PRISMIC_ArticleBodyText {
                primary {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`

const articleSerializer = createRichTextSerializer({
  paragraphClassname: () => 'mt-6 mx-8 lg:mx-0 text-lg',
  heading2Classname: () => 'mx-8 lg:mx-0 mt-8 -mb-2',
})

const fullBioSerializer = createRichTextSerializer({
  paragraphClassname: ({ index }) =>
    classnames('font-sans leading-loose text-base text-blue-dianne', {
      'mt-4': index === 0,
      'mt-2': index > 0,
    }),
  aClassname: () =>
    'text-blue-dianne font-sane leading-loose text-base hover:no-underline',
})

class ArticlePage extends React.PureComponent {
  state = {
    modalActive: false,
    mobileBioActive: false,
    mobileBioActive2: false,
    isImageModalOpen: false,
    imageModalImage: '',
    authorMobileBioActive: false,
    authorBioActive: false,
    modalExpert: {},
  }

  constructor(props) {
    super(props)

    if (typeof window !== 'undefined') {
      this.media = window.matchMedia('screen and (min-width: 900px)')
    }
  }

  showModal = expert => {
    this.setState({ modalActive: true, modalExpert: expert })
  }

  hideModal = () => {
    this.setState({ modalActive: false })
  }

  showMobileBio = () => {
    this.setState({ mobileBioActive: true })
  }

  showMobileBio2 = () => {
    this.setState({ mobileBioActive2: true })
  }

  showAuthorBio = () => {
    this.setState({ authorBioActive: true })
  }

  showAuthorMobileBio = () => {
    this.setState({ authorMobileBioActive: true })
  }

  hideMobileBio = () => {
    this.setState({ mobileBioActive: false })
  }

  hideMobileBio2 = () => {
    this.setState({ mobileBioActive2: false })
  }

  hideAuthorMobileBio = () => {
    this.setState({ authorMobileBioActive: false })
  }

  hideAuthorBio = () => {
    this.setState({ authorBioActive: false })
  }

  getApplicationNode = () => {
    if (document) {
      return document.getElementById('___gatsby')
    }
  }

  render() {
    const {
      data: {
        prismic: { article, latestArticles: latestArticlesCollection },
      },
    } = this.props

    const { isImageModalOpen, imageModalImage } = this.state

    if (!article) {
      return null
    }

    const hasPodcast =
      article.body && article.body.some(slice => slice.type === 'podcast')

    const latestArticlesRaw = latestArticlesCollection.edges
      .map(edge => edge.node)
      .filter(
        latestArticle =>
          latestArticle._meta.uid !== article._meta.uid &&
          latestArticle.is_hidden !== 'HIDDEN'
      )
      .slice(0, 3)

    const calculatedExcerpts = latestArticlesRaw.reduce((acc, article) => {
      const firstParagraph =
        article.body && article.body[0].primary
          ? article.body[0].primary.text
          : null

      if (!firstParagraph) {
        return { ...acc, [article._meta.uid]: '' }
      }

      const full = article.body ? RichText.asText(firstParagraph) : ''

      return {
        ...acc,
        [article._meta.uid]:
          full.slice(0, 160) +
          (full.slice(0, 160).substr(-1) === '.' ? '' : '...'),
      }
    }, {})

    const latestArticles = latestArticlesRaw.map(article => ({
      uid: article._meta.uid,
      title: article.title,
      description: article.excerpt || calculatedExcerpts[article._meta.uid],
      image: article.image ? article.image.url : latestArticleImage,
      createdAt: article._meta.firstPublicationDate,
      category: article.focusarea,
    }))

    return (
      <Layout stickyMenu forceMenuHide={this.state.authorBioActive}>
        <SEO
          title={article.title}
          keywords={[`longevity`]}
          image={article.image ? article.image.url : undefined}
        />

        <div className="container lg:max-w-xl mx-auto mt-24 text-blue-dianne border-dusty-gray lg:pb-16 mb-16 lg:mb-32 border-b">
          <div className="lg:flex items-start justify-between">
            <div className="measure text-lg">
              <div className="flex items-center mx-8 lg:mx-0">
                <FacebookShareButton
                  url={
                    typeof window === 'undefined'
                      ? 'https://l-institute.com'
                      : window.location.href
                  }
                  quote={article.title}
                >
                  <span className="block flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      className="text-blue-dianne text-base"
                      size="xs"
                      style={{ maxWidth: 20 }}
                      fixedWidth
                    />
                  </span>
                </FacebookShareButton>
                <LinkedinShareButton
                  url={
                    typeof window === 'undefined'
                      ? 'https://l-institute.com'
                      : window.location.href
                  }
                >
                  <span className="block flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className="text-blue-dianne text-base"
                      size="xs"
                      style={{ maxWidth: 20 }}
                      fixedWidth
                    />
                  </span>
                </LinkedinShareButton>
                <TwitterShareButton
                  url={
                    typeof window === 'undefined'
                      ? 'https://l-institute.com'
                      : window.location.href
                  }
                  title={article.title}
                >
                  <span className="block flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="text-blue-dianne text-base"
                      size="xs"
                      style={{ maxWidth: 20 }}
                      fixedWidth
                    />
                  </span>
                </TwitterShareButton>
                <button
                  onClick={() => window.print()}
                  className="block mr-6 flex items-center justify-center"
                >
                  <FontAwesomeIcon
                    icon={faPrint}
                    className="text-blue-dianne text-base"
                    size="xs"
                    style={{ maxWidth: 20 }}
                    fixedWidth
                  />
                </button>
                <EmailShareButton
                  url={
                    typeof window === 'undefined'
                      ? 'https://l-institute.com'
                      : window.location.href
                  }
                  subject={article.title}
                >
                  <span className="block flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-blue-dianne text-base"
                      size="xs"
                      style={{ maxWidth: 20 }}
                      fixedWidth
                    />
                  </span>
                </EmailShareButton>
              </div>
              <p className="text-xs mb-8 font-sans uppercase mx-8 lg:mx-0 mt-8">
                {moment(Date(article._meta.firstPublicationDate)).format(
                  'MMMM DD, YYYY'
                )}
                <span className="mx-2">&bull;</span>
                <Link
                  to={`/insights/${article.focusarea._meta.uid}`}
                  className={classnames(
                    'no-underline hover:underline font-bold',
                    `text-${article.focusarea._meta.uid}`
                  )}
                >
                  {article.focusarea.name}
                </Link>
              </p>
              <h1 className="text-2xl font-serif font-normal mx-8 lg:mx-0">
                {article.title}
              </h1>
              {article.expert && (
                <div
                  className={classnames('lg:hidden mx-8 mt-8', {
                    'mb-16': !article.expert2,
                    'mb-12': article.expert2,
                  })}
                >
                  {article.expert.photo && (
                    <img
                      src={article.expert.photo.url}
                      alt=" "
                      className="w-16"
                    />
                  )}
                  <p className="font-sans text-sm mt-8">
                    {article._meta.uid ===
                    'preventive-health-a-geopolitical-and-macroeconomic-necessity'
                      ? 'Author'
                      : 'Interview with'}
                    :
                  </p>
                  <p className="font-serif text-xl mt-4">
                    {article.expert.name}
                  </p>
                  <p className="font-sans leading-normal text-base mt-4">
                    {article.expert.short_bio}
                  </p>
                  <button
                    onClick={this.showMobileBio}
                    className="block mt-4 font-sans text-blue-dianne text-sm uppercase no-underline"
                  >
                    Read bio
                  </button>

                  {this.state.mobileBioActive && (
                    <div className="bg-selago p-8 mt-8">
                      <div className="flex items-center justify-between">
                        <p className="font-sans uppercase text-xs">bio:</p>
                        <button
                          onClick={this.hideMobileBio}
                          className="focus:outline-none"
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="block text-blue-dianne text-xs"
                          />
                        </button>
                      </div>
                      {RichText.render(
                        this.state.modalExpert.full_bio,
                        undefined,
                        fullBioSerializer
                      )}
                      <div className="flex">
                        <button
                          onClick={this.hideMobileBio}
                          className="ml-auto font-sans uppercase text-blue-dianne text-sm mt-4"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {article.expert2 && (
                <div className="lg:hidden mx-8 mt-8 mb-16">
                  {article.expert2.photo && (
                    <img
                      src={article.expert2.photo.url}
                      alt=" "
                      className="w-16"
                    />
                  )}
                  <p className="font-sans text-sm mt-8">
                    {article._meta.uid ===
                    'preventive-health-a-geopolitical-and-macroeconomic-necessity'
                      ? 'Author'
                      : 'Interview with'}
                    :
                  </p>
                  <p className="font-serif text-xl mt-4">
                    {article.expert2.name}
                  </p>
                  <p className="font-sans leading-normal text-base mt-4">
                    {article.expert2.short_bio}
                  </p>
                  <button
                    onClick={this.showMobileBio2}
                    className="block mt-4 font-sans text-blue-dianne text-sm uppercase no-underline"
                  >
                    Read bio
                  </button>

                  {this.state.mobileBioActive2 && (
                    <div className="bg-selago p-8 mt-8">
                      <div className="flex items-center justify-between">
                        <p className="font-sans uppercase text-xs">bio:</p>
                        <button
                          onClick={this.hideMobileBio2}
                          className="focus:outline-none"
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="block text-blue-dianne text-xs"
                          />
                        </button>
                      </div>
                      {article.expert2.full_bio
                        .split('\n')
                        .map((bioParagraph, bioIndex) => (
                          <p
                            className="font-sans leading-loose mt-4 text-base text-blue-dianne"
                            key={bioIndex}
                          >
                            {bioParagraph}
                          </p>
                        ))}
                      <div className="flex">
                        <button
                          onClick={this.hideMobileBio2}
                          className="ml-auto font-sans uppercase text-blue-dianne text-sm mt-4"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {article.body && (
                <article className="copy">
                  {article.body.map((slice, index) => {
                    if (slice.type === 'text') {
                      return (
                        <React.Fragment key={index}>
                          {RichText.render(
                            slice.primary.text,
                            undefined,
                            articleSerializer
                          )}
                        </React.Fragment>
                      )
                    }

                    if (slice.type === 'quote') {
                      return (
                        <div className="flex items-start lg:my-16" key={index}>
                          <img
                            src={quoteIcon}
                            alt=""
                            className="w-8 mb-2 mr-2 text-gotic flex-no-shrink"
                          />
                          <blockquote
                            key={index}
                            className="ml-2 lg:mr-12 text-xl text-blue-dianne"
                          >
                            {slice.primary.quote[0].text}
                          </blockquote>
                        </div>
                      )
                    }

                    if (slice.type === 'image' && slice.primary.image) {
                      return (
                        <div key={index} className="mt-16 mb-10">
                          <button
                            onClick={() =>
                              this.setState({
                                isImageModalOpen: true,
                                imageModalImage: slice.primary.image.url,
                              })
                            }
                          >
                            <img
                              src={slice.primary.image.url}
                              alt={slice.primary.image.alt || ''}
                              className="db w-full"
                            />
                          </button>
                          {slice.primary.caption && (
                            <p className="mt-0 text-gothic font-sans text-center text-sm italic">
                              {slice.primary.caption}
                            </p>
                          )}
                        </div>
                      )
                    }

                    if (
                      slice.type === 'podcast' &&
                      slice.primary.soundcloud_embed
                    ) {
                      // @FIXME
                      return (
                        <div
                          key={index}
                          className="mt-16 mb-10"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.soundcloud_embed.html
                              .replace('visual=true', 'visual=false')
                              .replace('artwork=true', 'artwork=false')
                              .replace('height="400"', 'height="166"'),
                          }}
                        ></div>
                      )
                    }

                    return null
                  })}
                </article>
              )}
              <ModalGateway>
                {isImageModalOpen && (
                  <Modal
                    onClose={() => this.setState({ isImageModalOpen: false })}
                    allowFullscreen={false}
                  >
                    <Carousel
                      views={[{ src: imageModalImage }]}
                      components={{ Footer: null }}
                    />
                  </Modal>
                )}
              </ModalGateway>
            </div>
            {((article.highlights && article.highlights.length > 0) ||
              article.expert ||
              article.expert2) && (
              <div className="mt-8 lg:mt-0 lg:w-1/4 lg:ml-12">
                {article.expert && (
                  <div className="hidden lg:block">
                    {article.expert.photo && (
                      <img
                        src={article.expert.photo.url}
                        alt=" "
                        className="w-24"
                      />
                    )}
                    <p className="font-sans text-sm mt-8">
                      {article._meta.uid ===
                      'preventive-health-a-geopolitical-and-macroeconomic-necessity'
                        ? 'Author'
                        : 'Interview with'}
                      :
                    </p>
                    <p className="font-serif text-xl mt-4">
                      {article.expert.name}
                    </p>
                    <p className="font-sans leading-normal text-base mt-4">
                      {article.expert.short_bio}
                    </p>
                    <button
                      onClick={() => this.showModal(article.expert)}
                      className="block mt-4 font-sans text-blue-dianne text-sm uppercase no-underline focus:outline-none"
                    >
                      Read bio
                    </button>
                  </div>
                )}
                {article.expert2 && (
                  <div
                    className={classnames('hidden lg:block', {
                      'mt-12': article.expert,
                    })}
                  >
                    {article.expert2.photo && (
                      <img
                        src={article.expert2.photo.url}
                        alt=" "
                        className="w-24"
                      />
                    )}
                    <p className="font-sans text-sm mt-8">
                      {article._meta.uid ===
                      'preventive-health-a-geopolitical-and-macroeconomic-necessity'
                        ? 'Author'
                        : 'Interview with'}
                      :
                    </p>
                    <p className="font-serif text-xl mt-4">
                      {article.expert2.name}
                    </p>
                    <p className="font-sans leading-normal text-base mt-4">
                      {article.expert2.short_bio}
                    </p>
                    <button
                      onClick={() => this.showModal(article.expert2)}
                      className="block mt-4 font-sans text-blue-dianne text-sm uppercase no-underline focus:outline-none"
                    >
                      Read bio
                    </button>
                  </div>
                )}
                {article.highlights.length > 0 && (
                  <div
                    className={classnames('bg-selago px-8 py-8 font-sans', {
                      'mt-16': article.expert || article.expert2,
                    })}
                  >
                    <h2 className="font-serif font-normal pb-8 mb-8 border-dusty-gray border-b">
                      Highlights
                    </h2>
                    <ul className="leading-normal lg:pl-6">
                      {article.highlights.map((highlight, index) => (
                        <li className="mb-4 text-blue-dianne" key={index}>
                          {highlight.highlight_text}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
          {article.author && (
            <div className="lg:flex items-start justify-center">
              <div className="text-lg flex my-16 items-start px-8 lg:px-0 lg:w-30em">
                <div className="w-16 mr-4">
                  {article.author.photo && (
                    <img src={article.author.photo.url} alt="" />
                  )}
                </div>
                <div className="pt-2 font-sans text-blue-dianne">
                  <p className="text-xs">
                    {article.author_section_title || 'Author'}
                  </p>
                  <p className="font-serif text-lg mt-2">
                    {article.author.name}
                  </p>
                  <p className="font-sans text-base mt-3 measure">
                    {article.author.title}
                  </p>
                  {article.author.bio && (
                    <button
                      onClick={() => {
                        this.media.matches
                          ? this.showAuthorBio()
                          : this.showAuthorMobileBio()
                      }}
                      className="block mt-4 font-sans text-gothic text-xs uppercase no-underline"
                    >
                      Read bio
                    </button>
                  )}
                </div>
              </div>
              <div className="hidden lg:block mt-8 lg:mt-0 lg:w-1/4 lg:ml-12 px-8 py-8 font-sans h-px" />
            </div>
          )}
          {article.author &&
            article.author.bio &&
            this.state.authorMobileBioActive && (
              <div className="bg-selago p-8 mt-8">
                <div className="flex items-center justify-between">
                  <p className="font-sans uppercase text-xs">bio:</p>
                  <button
                    onClick={this.hideAuthorMobileBio}
                    className="focus:outline-none"
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="block text-blue-dianne text-xs"
                    />
                  </button>
                </div>
                {article.author.bio.split('\n').map((bioParagraph, index) => (
                  <p
                    className="font-sans leading-loose mt-4 text-base text-blue-dianne"
                    key={index}
                  >
                    {bioParagraph}
                  </p>
                ))}
                <div className="flex">
                  <button
                    onClick={this.hideAuthorMobileBio}
                    className="ml-auto font-sans uppercase text-blue-dianne text-sm mt-4"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
        </div>

        <div className="container mx-auto mt-16 mb-16 lg:mb-32 text-blue-dianne">
          <h2 className="mx-8 lg:mx-0 font-normal font-serif text-2xl">
            {hasPodcast ? 'Latest Podcasts' : 'Latest Insights'}
          </h2>
          <ArticlesColumn articles={latestArticles} />
          <div className="flex mx-8 lg:mx-auto mt-16 justify-center">
            <Link
              to={`/insights/${article.focusarea._meta.uid}`}
              className="font-serif text-lg text-blue-dianne bg-selago px-8 py-5 flex items-center no-underline"
            >
              <span className="cta-ie11-fix">Show all Insights</span>{' '}
              <img src={arrow} alt="" className="ml-8 h-6" />
            </Link>
          </div>
        </div>

        {article.expert && this.state.modalActive && (
          <AriaModal
            titleText="Expert"
            onExit={this.hideModal}
            underlayStyle={{}}
            underlayColor={false}
          >
            <div className="hidden lg:block max-w-md mt-16 bg-selago p-12">
              <div className="flex">
                <button
                  onClick={this.hideModal}
                  className="ml-auto focus:outline-none"
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-blue-dianne text-base"
                  />
                </button>
              </div>
              <div className="flex items-start text-blue-dianne">
                <div>
                  {this.state.modalExpert.photo && (
                    <img
                      src={this.state.modalExpert.photo.url}
                      alt=" "
                      className="w-24"
                    />
                  )}
                </div>
                <div className="ml-8">
                  <p className="font-sans text-sm">
                    {article._meta.uid ===
                    'preventive-health-a-geopolitical-and-macroeconomic-necessity'
                      ? 'Author'
                      : 'Interview with'}
                    :
                  </p>
                  <p className="font-serif text-xl mt-4">
                    {this.state.modalExpert.name}
                  </p>
                  <p className="font-sans leading-normal mt-4 text-base">
                    {this.state.modalExpert.title}
                  </p>
                </div>
              </div>
              {RichText.render(
                this.state.modalExpert.full_bio,
                undefined,
                fullBioSerializer
              )}
            </div>
          </AriaModal>
        )}
        {article.author && article.author.bio && this.state.authorBioActive && (
          <AriaModal
            titleText="Author"
            onExit={this.hideAuthorBio}
            underlayStyle={{}}
            underlayColor={false}
          >
            <div className="hidden lg:block max-w-md mt-16 bg-selago p-12">
              <div className="flex">
                <button
                  onClick={this.hideAuthorBio}
                  className="ml-auto focus:outline-none"
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-blue-dianne text-base"
                  />
                </button>
              </div>
              <div className="flex items-start text-blue-dianne">
                <div>
                  {article.author.photo && (
                    <img
                      src={article.author.photo.url}
                      alt=" "
                      className="w-24"
                    />
                  )}
                </div>
                <div className="ml-8">
                  <p className="font-serif text-xl mt-4">
                    {article.author.name}
                  </p>
                  <p className="font-sans leading-normal mt-4 text-base">
                    {article.author.title}
                  </p>
                </div>
              </div>
              {article.author.bio.split('\n').map((bioParagraph, index) => (
                <p
                  className="font-sans leading-loose mt-4 text-base text-blue-dianne"
                  key={index}
                >
                  {bioParagraph}
                </p>
              ))}
            </div>
          </AriaModal>
        )}
      </Layout>
    )
  }
}

export default ArticlePage
